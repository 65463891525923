import React from "react";
import Carousel from 'react-bootstrap/Carousel'
import { Link } from "react-router-dom";
import ProductByCategory from "../ProductsByCategory";
import Products from "./Products";


export default class Home extends React.Component{

    private selectedCategory : string = "";
  
    componentDidMount(): void {
      this.selectedCategory = "";
    }

    private onCategoryClick(category: string) {
      console.log(`Home Selected category : ${category}`);
      this.selectedCategory = category;
      this.forceUpdate();
    }

    render(): React.ReactNode {
        if (this.selectedCategory) {
          return (
            <Products category={this.selectedCategory} />
          );
        } else 
        return(
            <div className="Home">
            <div className="slider">
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" src="./assets/images/home/slider/slider1.jpg" alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="./assets/images/home/slider/slider2.jpg" alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="./assets/images/home/slider/slider3.jpg" alt="First slide" />
                </Carousel.Item>
              </Carousel>
            </div>
            {/* <!-- Start Featured Product --> */}
            <section className="">
              <div className="container py-5">
                <div className="row text-center py-3">
                  <div className="col-lg-6 m-auto">
                    <h2 className="h2">Featured Product</h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 mb-4">
                    <div className="card h-100">
                      <a onClick={() => this.onCategoryClick(`Bedsheets`)}>
                        <img src="./assets/images/home/featuredproduct/bedsheet.jpg" className="card-img-top img-fluid" style={{width:'100%', height:'400px'}} alt="..." />
                      </a>
                      <div className="card-body bg-dark text-center" >
                        {/* <a href="/Products category={`Bedsheets`}" className="h4 color-white text-decoration-none featuredprod">Bedsheet</a> */}
                        <a onClick={() => this.onCategoryClick(`Bedsheets`)} className="h4 color-white text-decoration-none featuredprod">Bedsheet</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-4">
                    <div className="card h-100">
                      <a onClick={() => this.onCategoryClick(`Dohar`)}>
                        <img src="./assets/images/home/featuredproduct/dohar.jpg" className="card-img-top img-fluid" style={{width:'100%', height:'400px'}} alt="..." />
                      </a>
                      <div className="card-body bg-dark text-center" >
                        <a onClick={() => this.onCategoryClick(`Dohar`)} className="h4 color-white text-decoration-none featuredprod">Dohar</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-4">
                    <div className="card">
                      <a onClick={() => this.onCategoryClick(`Women's Wear`)}>
                        <img src="./assets/images/home/featuredproduct/2.jpg" className="card-img-top img-fluid" alt="..." style={{width:'100%', height:'400px'}} />
                      </a>
                      <div className="card-body bg-dark text-center" >
                        <a  onClick={() => this.onCategoryClick(`Women's Wear`)} className="h4 color-white text-decoration-none featuredprod">Women's Wear</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <!-- End Featured Product --> */}
      
          </div>
        );
    }
}