import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './component/Home';
import Products from './component/Products';
import ProductDetails from './component/ProductDetails';
import logoimage from './assets/logo/l3.png';


function App() {
  return (
    <Router>
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-4">
          <div className="container d-flex justify-content-between align-items-center">
          {/* <a className="navbar-brand logo h2 text-dark align-self-center" href="">Samyak Decors</a> */}
           <a className="navbar-brand text-dark align-self-center" href="">
            <img src='./assets/logo/l4.png'/>
           </a>
            <button className="navbar-toggler border-0 " type="button" data-bs-toggle="collapse" data-bs-target="#templatemo_main_nav" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon text-warning"></span>
            </button>
            <div className="align-self-center collapse navbar-collapse menu-text" id="templatemo_main_nav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item active menu-text">
                  <a className="nav-link menu-text" href="/Home">Home</a>
                </li>
                <li className="nav-item menu-text">
                  <a className="nav-link menu-text" href="/Products">Products</a>
                </li>
                <li className="nav-item menu-text">
                  <a className="nav-link menu-text" href="#tempaltemo_footer">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/products' element={<Products />} />
          <Route path='/productdetails' element={<ProductDetails/>} />
        </Routes>

        <div className="Footer">
          <footer className="bg-dark" id="tempaltemo_footer">
            <div className="container text-center">
              <div className="row">
              <div className="col-9 pt-5 text-left">
                  <h4 className="text-warning">Contact Us</h4>
                  <ul className="list-unstyled text-light footer-link-list" >
                    <li>
                      <i className="fa fa-phone fa-fw"></i>
                      <a className="text-decoration-none" href="tel:7204274343">&nbsp;720 427 4343</a>
                    </li>
                    <li>
                      <a className="text-decoration-none" href="tel:7204274343">
                        <img className="social-img" src="./assets/logo/whatsapp.png"></img>&nbsp;720 427 4343</a>
                    </li>
                    <li>
                      <i className="fa fa-envelope fa-fw"></i>
                      <a className="text-decoration-none" href="mailto:samyak.homedecors@gmail.com">&nbsp;samyak.homedecors@gmail.com</a>
                      </li>
                      <li>
                      <i className="fa fa-envelope fa-fw"></i>
                      <a className="text-decoration-none" href="mailto:contact@samyakdecors.com">&nbsp;contact@samyakdecors.com</a>
                    </li>
                  </ul>
                </div>
                <div className="col-3 pt-5 text-right">
                <h4 className="text-warning">Visit us at</h4>
                  <ul className="list-unstyled text-light footer-link-list" >
                  <li>
                      <a className="seller-img" href='https://www.flipkart.com/search?q=samyak%20decors&marketplace=FLIPKART' target="_blank"><img className="seller-img" src='./assets/logo/flipkart.png'/></a>
                      &nbsp;&nbsp;&nbsp;&nbsp;<a className="seller-img" href='https://www.amazon.in/stores/SamyakDecors/page/89C85572-DD5D-48F7-A011-C9E4A5BC3AA7' target="_blank"><img className="seller-img" src='./assets/logo/amazon.png'/></a>
                    </li>
                  </ul>
                </div>
                
              </div>
              <div className="w-100 bg-dark">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <hr className="border-bottom border-warning"/>
                      <div className="text-center text-light copyright">
                        Copyright &copy; 2024 Samyak Decors
                        Designed & Developed by <a rel="sponsored" target="_blank">Manisha</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>

      </div>
    </Router>
  );
}

export default App;
