import { start } from "repl";
import ProductData from "./ProductsData";

export enum FILTER {
    NONE,
    PRICE_LOW_TO_HIGH,
    PRICE_HIGH_TO_LOW
}

export default class ProductByCategory {

    public static ALL_CATEGORY_NAME = "All Products";
    private productsByCateogory: Map<string, ProductData[]>;
    private filter_category: FILTER = FILTER.NONE;
    private filter_size: string[] = [];

    constructor() {
        this.productsByCateogory = new Map();
    }

    public addProduct(productData: ProductData) {
        const category = productData.getCategory();
      //  console.log(`AddProduct with category : ${category} => ${JSON.stringify(productData)}`)
        let products = this.productsByCateogory.get(category);
        if (!products) {
            products = [];
        }
        products.push(productData);
        this.productsByCateogory.set(category, products);
    }

    public getProductsByCategoryByPage(category: string, pageIndex: number, pageSize: number = 30) {
     //   console.log(`Current Category : ${category}, pageindex: ${pageIndex}, size : ${pageSize}`);

        let product: ProductData[] = this.getProductsByCategory(category);
        let startElement = (pageIndex) * pageSize;
        let endElement = startElement + pageSize;
        return product.slice(startElement, endElement);
    }

    public getProductsByCategory(category: string): ProductData[] {
        if (category === ProductByCategory.ALL_CATEGORY_NAME) {

            let allProducts: ProductData[] = [];
            this.productsByCateogory.forEach((value, key) => {
                allProducts = allProducts.concat(value);
            });

            return this.applyFilter(allProducts);
        }

        let products = this.productsByCateogory.get(category);
        return (products) ? this.applyFilter(products) : [];
    }



    public getListOfCategories(): string[] {
        let k: string[] = [];
        this.productsByCateogory.forEach((value, key) => {
            k.push(key);
        });
        return k;
    }

    public clear() {
        this.productsByCateogory.clear();
    }

    public setFilterSize(checkedValue: string[]) {
        this.filter_size = checkedValue;
        console.log("set filter size called ", this.filter_size.length);
    }

    public setFilterCategory(filter: string) {
        if (filter === "PriceLowToHigh") {
            this.filter_category = FILTER.PRICE_LOW_TO_HIGH;
        }
        else if (filter === "PriceHighToLow") {
            this.filter_category = FILTER.PRICE_HIGH_TO_LOW;
        }
        else {
            this.filter_category = FILTER.NONE;
        }
    }

    private applyFilter(productData: ProductData[]): ProductData[] {
    //    console.log("apply filter called ");

        let filterProduct: ProductData[] = [];
        if (this.filter_size && this.filter_size.length > 0) {
            for (let index = 0; index < productData.length; index++) {
                if (!this.isEmptyString(productData[index].getSize()) && this.filter_size.includes(productData[index].getSize())) {
                    filterProduct.push(productData[index]);
                    console.log("apply filter filled by productdata ", filterProduct);

                }
            }

        }
        else{
            filterProduct = productData;
      //      console.log("apply filter empty ", filterProduct);

        }
        if (this.filter_category === FILTER.PRICE_LOW_TO_HIGH) {
            return filterProduct.sort((a, b) => a.getSalesPrice() - b.getSalesPrice());
        } else if (this.filter_category === FILTER.PRICE_HIGH_TO_LOW) {
            return filterProduct.sort((a, b) => b.getSalesPrice() - a.getSalesPrice());
        } else {
            return filterProduct;
        }
    }


    public getUniqueProductSize(category: string): string[] {
        if (category === ProductByCategory.ALL_CATEGORY_NAME) {
            return [];
        }
        let products : ProductData [] | undefined = this.productsByCateogory.get(category);
        let setSize = new Set<any>();
        if (products) {
            for (let index = 0; index < products.length; index++) {
                if (!this.isEmptyString(products[index].getSize())) {
                    setSize.add(products[index].getSize());
                }
            }
        }   
     //   console.log("Unique Product Size", setSize);
        return Array.from(setSize);
    }

    private isEmptyString(data: string) {
        if (data && data.trim() !=="") {
            return false;
        }
        return true;
    }
}