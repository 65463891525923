import React from "react";
import ProductData from "../ProductsData";
import ProductContext from "./ProductContext";
import { IProductModel } from "./IProductModel";
import { Breadcrumb, Container } from "react-bootstrap";
import Products from "./Products";


export default class ProductDetails extends React.Component<IProductModel> {
    private selectedProduct: ProductData | null = null;;
    private pickedImage: string = "";
    private backToProductPage = false;
    // private selectedProduct : ProductData;
    constructor(props: any) {
        super(props);
        console.log(`props = ${props}`);
    }

    componentDidMount(): void {
        this.selectedProduct = this.props.product ? this.props.product : null;
        this.backToProductPage = false;
        this.pickedImage = (this.selectedProduct && this.selectedProduct.getImage1()) ? this.selectedProduct.getImage1() : "";
        console.log(`Product Details mount : ${JSON.stringify(this.selectedProduct)} and pickedImage = ${this.pickedImage}`)
        this.forceUpdate();
    }

    private onPickedImage(imageUrl: string) {
        console.log(`onPickedImage : ${imageUrl}`);
        this.pickedImage = imageUrl;
        this.forceUpdate();
    }

    private onGoBackToProductPage() {
        this.backToProductPage = true;
        this.forceUpdate();
    }

    render() {
        const selectedProduct = this.props.product;
        if (this.backToProductPage) {
            return (
                <Products category={this.selectedProduct?.getCategory()}/>
            )
        } else 
        return (
            <section>
                <div className="container pb-5">
                {/* <hr className="border-bottom border-warning" /> */}

                {
                    this.selectedProduct && this.pickedImage && (


                        // <div className="container pt-4">
                        <div className="pt-4">
                            <Breadcrumb className="breadcrums">
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item onClick={() => this.onGoBackToProductPage()}>
                                    {
                                        this.selectedProduct.getCategory()
                                    }
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div className="row">
                                <div className="col-lg-5 mt-2">
                                    <div className="card mb-3">
                                        {/* <img id="product-detail" className="card-img img-fluid" src="assets/img/product_single_10.jpg" alt="Card image cap"/> */}
                                        <img id="product-detail" className="card-img img-fluid" src={this.pickedImage} alt="Card image cap" style={{width:'100%', height:'auto'}} />
                                    </div>
                                    <div className="row mb-5">

                                        <div id="multi-item-example" className="col-10" >
                                            <div className="product-links-wap" role="listbox">
                                                <div className="row">
                                                    {
                                                        this.selectedProduct.getCollatedImages().map((imageUrl: string, index: number) => (

                                                            <div className="col-2" >
                                                                <img key={index} className="card-img img-fluid" style={{ width: '40px', height: '40px' }} onClick={() => this.onPickedImage(imageUrl)} src={imageUrl} />
                                                            </div>

                                                        ))
                                                    }
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-7 mt-2">
                                    <div className="card container-shadow mb-4">
                                        <div className="card-body">
                                            <h4 className=" text-left">{this.selectedProduct.getProductName()}</h4>
                                            <h5 className="pt-3 text-left">{this.selectedProduct.getSku()}</h5>

                                            <p className="py-2 text-left">
                                            <span className="sale-price ">&#8377;{this.selectedProduct.getSalesPrice()}</span>&nbsp; MRP <del>&#8377; {this.selectedProduct.getMrp()}</del>   
                                                {!this.selectedProduct.getAvailablility() && (<span className="sold-out"> &nbsp; &nbsp; &nbsp;SOLD OUT</span>)}
                                            </p>
                                            <h5 className="pt-3 text-left">Buy From</h5>
                                                {
                                                        <ul className="text-left description"> 
                                                        <a  href={`https://www.flipkart.com/product/p/itme?pid=${this.selectedProduct.getFlipkarFSN()}`} target="_blank"><img className="seller-img-large" src='./assets/logo/flipkart.png'/></a>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                        <a href={`https://www.amazon.in/gp/product/${this.selectedProduct.getAmazonASIN()}`} target="_blank"><img className="seller-img-large" src='./assets/logo/amazon.png'/></a>
                                                        </ul>
                                
                                                }
                                          
                                            
                                            <h5 className="pt-3 text-left">Specification</h5>
                                            <ul className="description pb-3">
                                                {
                                                    this.selectedProduct.getAllSpecifications().map((specs, index) => (
                                                        <li className="text-left description">{specs}</li>
                                                    ))
                                                }
                                            </ul>

                                            <h5 className="pt-3 text-left">Available Sizes</h5>
                                            <ul className="description pb-3">
                                                {
                                                    

                                                    <li className="text-left description">{this.selectedProduct.getSize().replaceAll("-",", ")}</li>
                                                    
                                                }
                                            </ul>


                                            <h5 className="pt-3 text-left">Package Contains</h5>
                                            <ul className="description pb-3">
                                                {
                                                    this.selectedProduct.getAllPackageContains().map((specs, index) => (
                                                        <li className="text-left description">{specs}</li>
                                                    ))
                                                }
                                            </ul>

                                            <h5 className="pt-3 text-left">Key Features</h5>
                                            <ul className="description pb-3">
                                                {
                                                    this.selectedProduct.getAllFeaturesFromKeyFeatures().map((feature, index) => (
                                                        <li className="text-left description">{feature}</li>
                                                    ))
                                                }
                                            </ul>

                                            <h5 className="pt-3 text-left">Description</h5>
                                            <p className="description">{this.selectedProduct.getDescription()}</p>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    )
                }
                </div>
            </section>


        );
    }
}