import React from "react";
import { Container, Row, Col, Card, Carousel, Dropdown, Pagination, FormCheck } from "react-bootstrap";
import ProductData from "../ProductsData";
import datacsv from '../data.csv'
import ProductByCategory from "../ProductsByCategory";
import ProductDetails from "./ProductDetails";
import ProductContext from "./ProductContext";
import { validateHeaderValue } from "http";
import { ICategoryModel } from "./IProductModel";



export default class Products extends React.Component<ICategoryModel> {


    private CATEGORY: string = 'Category';
    private CATEGORY_HEADER = 0;
    private PRODUCTNAME_HEADER = 1;
    private SKU_HEADER = 2;
    private FLIPKART_FSN_HEADER = 3;
    private AMAZON_ASIN_HEADER = 4;
    private SIZE_HEADER = 5;
    private AVAILABLE_HEADER = 6;
    private MRP_HEADER = 7;
    private SALESPRICE_HEADER = 8;
    private IMAGE1_HEADER = 9;
    private IMAGE2_HEADER = 10;
    private IMAGE3_HEADER = 11;
    private IMAGE4_HEADER = 12;
    private KEYFEATURE_HEADER = 13;
    private SPECIFICATION_HEADER = 14;
    private PACKAGECONTAINS_HEADER = 15;
    private DESCRIPTION_HEADER = 16;


    private productsByCat = new ProductByCategory();
    private selectedCategory: string = ProductByCategory.ALL_CATEGORY_NAME;
    private selectedProduct: ProductData = new ProductData();
    private isProductSelected: Boolean = false;
    private activePage: number = 1;
    private pageIndex: number = 0;
    private pageSize: number = 30;
    private selectedFilter: string = "None";
    private bedsize : string[] =[];

    constructor(props: any) {
        super(props);
        console.log(`props : ${props.category}`);
        this.handleProductCardClick = this.handleProductCardClick.bind(this);
    }

    componentDidMount(): void {
        console.log(`componentDidMount props : ${this.props.category}`);
        this.selectedCategory = this.props.category ? this.props.category : ProductByCategory.ALL_CATEGORY_NAME ;
        this.loadData();
    }

    private loadData() {
        console.log('Load data invoked.')
        fetch(datacsv)
            .then(response => { return response.text() })
            .then(data => {
                this.productsByCat.clear();

                const lines = data.split('\n');
                const headers = lines[0].split(',');
                console.log(`headers size : `, headers.length);
                const categoryIndex: number = headers.indexOf('Category');
                console.log(`categoryIndex : `, categoryIndex);

                for (let i = 1; i < lines.length; i++) {
                    const values = lines[i].split(',');

                    if (values[categoryIndex] && values[categoryIndex].trim() !== '') {
                        const productData = new ProductData();
                        productData.setCategory(values[this.CATEGORY_HEADER]);
                        productData.setProductName(values[this.PRODUCTNAME_HEADER]);
                        productData.setSku(values[this.SKU_HEADER]);
                        productData.setSize(values[this.SIZE_HEADER]);
                        productData.setAvailablility(values[this.AVAILABLE_HEADER]);
                        productData.setSalesPrice(Number(values[this.SALESPRICE_HEADER]));
                        productData.setMrp(Number(values[this.MRP_HEADER]));
                        productData.setImage1(values[this.IMAGE1_HEADER].trim());
                        productData.setImage2(values[this.IMAGE2_HEADER].trim());
                        productData.setImage3(values[this.IMAGE3_HEADER].trim());
                        productData.setImage4(values[this.IMAGE4_HEADER].trim());
                        productData.setKeyFeatures(values[this.KEYFEATURE_HEADER].trim());
                        productData.setSpecifications(values[this.SPECIFICATION_HEADER].trim());
                        productData.setDescription(values[this.DESCRIPTION_HEADER]);
                        productData.setPackageContains(values[this.PACKAGECONTAINS_HEADER]);
                        productData.setFlipkartFSN(values[this.FLIPKART_FSN_HEADER]);
                        productData.setAmazonASIN(values[this.AMAZON_ASIN_HEADER]);
                        this.productsByCat.addProduct(productData);
                        // console.log('productData :', JSON.stringify(productData));
                    }
                    this.forceUpdate();
                }
            })
            .catch(error => {
                console.error('Error reading data.csv: ', error);
            })
    }

    private handleCategoryClick(category: string) {
        this.selectedCategory = category;
        this.pageIndex = 0;
        this.selectedFilter = "None";
        this.forceUpdate();
    }


    private handleProductCardClick(product: ProductData) {
        console.log(`handleProductCardClick : ${JSON.stringify(product)}`);
        this.selectedProduct = product;
        this.isProductSelected = true;
        console.log(`handleProductCardClick this.state.selectedProduct: ${JSON.stringify(this.state)}`);
        this.forceUpdate();
    }

    private onPriceLowToHighClick() {
        console.log(`onPriceLowToHighClick : ${this.selectedCategory}`);
        this.productsByCat.setFilterCategory("PriceLowToHigh");
        this.selectedFilter = "Price Low to High";
        this.forceUpdate();
    }

    private onPriceHighToLowClick() {
        console.log(`onPriceHighToLowClick : ${this.selectedCategory}`);
        this.productsByCat.setFilterCategory("PriceHighToLow");
        this.selectedFilter = "Price High to Low";
        this.forceUpdate();
    }

    private handlePrevPageChange() {
        let totalNumberOfPage = this.productsByCat.getProductsByCategory(this.selectedCategory).length / this.pageSize;
        if (this.pageIndex - 1 < 0) {
        } else {
            this.pageIndex--;
        }
        this.forceUpdate();
    }

    private handleNextPageChange() {
        let totalNumberOfPage = this.productsByCat.getProductsByCategory(this.selectedCategory).length / this.pageSize;
        if (this.pageIndex + 1 > totalNumberOfPage) {
        } else {
            this.pageIndex++;
        }
        this.forceUpdate();

    }

    private  onInputCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("check",event.target.checked);
        
        if (event.target.checked){
            this.bedsize.push(event.target.name);
        }
        else{
        this.bedsize = this.bedsize.filter((value :string, index :number)=> value !== event.target.name);
        }
        console.log("bedsize",this.bedsize);
        this.productsByCat.setFilterSize(this.bedsize);
        this.forceUpdate();
    }

    render() {

        const listOfItems = this.productsByCat.getListOfCategories().map((category: string, index: number) => {
       //     console.log("Index  ", index);
            return category;
        });
        listOfItems.unshift(ProductByCategory.ALL_CATEGORY_NAME);
       // console.log(`list of categories : ${listOfItems}`)
        if (this.isProductSelected) {
            return (
                <div>
                    <ProductDetails product={this.selectedProduct} />
                </div>
            );

        }
        else {


            return (

                <div className="container py-5">


                    {/* <hr className="border-bottom border-warning" /> */}
                    {/* <Container> */}
                    <Row className="row">
                        <div className="col-lg-2 text-left category-pane shadow">
                            <Row className="row">
                                {/* <Col xs={2} className=" "> */}
                                <h5 className="pt-4 "> Category </h5>
                                <div className="py-3">
                                    {
                                        listOfItems.map((category: string, index: number) => (
                                            <div className="mr-4 pb-2">
                                                <a className="categories-text" href="#" key={category} onClick={() => this.handleCategoryClick(category)}>{category}</a>
                                            </div>
                                        ))
                                    }
                                </div>
                                <hr/> 
                                {
                                    (this.selectedCategory !== ProductByCategory.ALL_CATEGORY_NAME) && (
                                        <>
                                            <h5 className="pt-4"> Size </h5><div className="py-3">
                                                {this.productsByCat.getUniqueProductSize(this.selectedCategory).map((size: string, index: number) => (
                                                    <div className="mr-4 pb-2">
                                                        <div className="categories-text">
                                                            <input type="checkbox" key={index} name={size} value={size} onChange={this.onInputCheckboxChange} />&nbsp;&nbsp;{size.replaceAll("-", ", ")}
                                                        </div>
                                                    </div>
                                                ))}
                                                </div>
                                            <hr />
                                        </>
                                )
                                }

                            </Row>
                            {/* </Col> */}
                        </div>
                        <div style={{ width: '1px' }} />
                        <div className="col-lg-9 category-pane shadow">
                            {/* <Col> */}
                            {/* <h4> Products </h4> */}
                            {/* <div> Selected Category : {this.selectedCategory}</div> */}
                            <div className="row category-pane-dark-nohover shadow pt-3">
                                <div className="col-md-4 text-left">
                                    {/* <h5 className="mb-4"> {this.selectedCategory}</h5> */}
                                    Category: {this.selectedCategory}
                                </div>
                                <div className="col-md-4 text-left category-pane-dark mt-1">
                                    <h5 className="mb-4 category-pane-dark">
                                        <span className="category-pane-dark" onClick={() => (this.handlePrevPageChange())}> <a className="category-pane-dark" href="#">Prev</a></span>
                                        <span>  &nbsp; {this.pageIndex + 1} &nbsp; </span>
                                        <span onClick={() => (this.handleNextPageChange())}><a className="category-pane-dark" href="#">Next</a> </span>
                                    </h5>
                                </div>
                                <div className="col-md-4 text-left category-pane-dark p-0">
                                    <Dropdown className="category-pane-dark ">
                                        {/* <h5 className="mb-4 "> */}
                                        <Dropdown.Toggle className=" category-pane-dark" >
                                            Filters : {this.selectedFilter}
                                        </Dropdown.Toggle>
                                        {/* </h5> */}
                                        <Dropdown.Menu className="category-pane-dark">
                                            <Dropdown.Item className="category-pane-dark" href="#" onClick={() => this.onPriceLowToHighClick()}>
                                                Price: Low to High
                                            </Dropdown.Item>
                                            <Dropdown.Item className="category-pane-dark" href="#" onClick={() => this.onPriceHighToLowClick()}>
                                                Price: High to Low
                                            </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            {
                                this.selectedCategory && (
                                    <div className="row mt-4">

                                        {

                                            this.productsByCat.getProductsByCategoryByPage(this.selectedCategory, this.pageIndex, this.pageSize).map((product: ProductData, index: number) => (
                                                // <Col key={index} sm={4}>

                                                <div className="col-md-4">
                                                    <div className="card mb-4 product-wap rounded-0">
                                                        <div className="div-img card rounded-0" onClick={() => this.handleProductCardClick(product)} >
                                                            {/* <img className="d-block w-100" src={product.getImage1()} /> */}
                                                            <img className="img-pr card-img rounded-0 img-fluid" src={product.getImage1()} style={{ width: '700', height: '450px' }} />
                                                            {/* <Card.Img variant="top" src={product.getImage1()} style={{ width: '100%', height: '300px' }} /> */}
                                                            <div className="card-img-overlay rounded-0 product-overlay d-flex align-items-center justify-content-center">

                                                            </div>
                                                            {/* <Carousel interval={null}>
                                                                {
                                                                    product.getCollatedImages().map((image: string, index: number) => (
                                                                        <Carousel.Item key={index} >
                                                                            <img className="d-block w-100" src={image} alt="Product Image" />
                                                                        </Carousel.Item>
                                                                    ))
                                                                }
                                                            </Carousel> */}
                                                            <div className="card-body">
                                                                <div className="card-title">{product.getProductName()}</div>
                                                                <div className="text-left"><div> <span className="sale-price">&#8377;{product.getSalesPrice()}</span>   &nbsp;MRP <del>&#8377; {product.getMrp()}</del>
                                                                    {!product.getAvailablility() && (<span className="sold-out"> &nbsp; &nbsp; &nbsp;SOLD OUT</span>)}
                                                                </div></div>

                                                                {/* <Card.Text className="text-left color-red mrp-text">MRP : {product.getMrp()}</Card.Text> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                // </Col>
                                            ))
                                        }
                                    </div>
                                )
                            }
                            {/* </Col> */}
                            <div className="row category-pane-dark-nohover shadow pt-3">
                                <div className="col-md-4 text-left category-pane-dark mt-1">
                                    <h5 className="mb-4 category-pane-dark">
                                        <span className="category-pane-dark" onClick={() => (this.handlePrevPageChange())}> <a className="category-pane-dark" href="#">Prev</a></span>
                                        <span>  &nbsp; {this.pageIndex + 1} &nbsp; </span>
                                        <span onClick={() => (this.handleNextPageChange())}><a className="category-pane-dark" href="#">Next</a> </span>
                                    </h5>
                                </div>                               
                            </div>
                        </div>
                    </Row>
                    <div style={{ marginTop: '20px' }}></div>
                    {/* </Container> */}
                </div>
            );
        }
    }
}