
export default class ProductData {
    private category: string;
    private productName : string;
    private sku: string;
    private description : string;
    private salesPrice : number;
    private mrp : number;
    private image1 : string;
    private image2 : string;
    private image3 : string;
    private image4 : string;
    private keyFeatures: string;
    private specification: string;
    private isAvailable: boolean;
    private size: string;
    private packageContains: string;
    private flipkartFSN: string;
    private amazonASIN: string;


    constructor() {
        this.category = "";
        this.productName = "";
        this.sku = "";
        this.description = "";
        this.salesPrice = 0.0;
        this.mrp = 0.0;
        this.image1 = "";
        this.image2 = "";
        this.image3 = "";
        this.image4 = "";
        this.keyFeatures = "";
        this.specification = "";
        this.size = "";
        this.isAvailable = true;
        this.packageContains = "";
        this.amazonASIN = "";
        this.flipkartFSN = "";
    }

    public getCategory() : string {
        return this.category;
    }
    public setCategory(category: string) {
        this.category = category;
    }

    public getProductName() : string {
        return this.productName;
    }
    public setProductName(productName: string) {
        this.productName = productName;
    }

    public getSku() : string {
        return this.sku;
    }
    public setSku(sku : string) {
        this.sku = sku;
    }

    public getDescription() : string {
        return this.description;
    }
    public setDescription(description: string) {

        this.description = description.replaceAll('|', ",");
    }

    public getSalesPrice() {
        return this.salesPrice;
    }
    public setSalesPrice(salesPrice: number) {
        this.salesPrice = salesPrice;
    }

    public getMrp() {
        return this.mrp;
    }
    public setMrp(mrp : number) {
        this.mrp = mrp;
    }

    public getImage1() {
        return this.image1;
    }
    public setImage1(image: string) {
      //  console.log("image set "+image);
        this.image1 = this.formImageLink(image);
    }
    
    public getImage2() {
        return this.image2;
    }
    public setImage2(image: string) {
        this.image2 = this.formImageLink(image);;
    }

    public getImage3() {
        return this.image3;
    }
    public setImage3(image: string) {
        this.image3 = this.formImageLink(image);;
    }
    
    public getImage4() {
        return this.image4;
    }
    public setImage4(image: string) {
        this.image4 = this.formImageLink(image);
    }    

    public getCollatedImages() {
        const listOfImages = [];

        if (!this.isEmptyString(this.getImage1())) {
            listOfImages.push(this.getImage1());
        }
        if (!this.isEmptyString(this.getImage2())) {
            listOfImages.push(this.getImage2());
        }
        if (!this.isEmptyString(this.getImage3())) {
            listOfImages.push(this.getImage3());
        }
        if (!this.isEmptyString(this.getImage4())) {
            listOfImages.push(this.getImage4());
        }
        return listOfImages;
    }

    public getKeyFeatures() {
        return this.keyFeatures;
    }
    public setKeyFeatures(features: string) {
        this.keyFeatures = features;
    } 
    
    public getSpecifications() {
        return this.specification;
    }
    public setSpecifications(specification: string) {
        this.specification = specification;
    }

    public getPackageContains() {
        return this.packageContains;
    }
    public setPackageContains(contains: string) {
        this.packageContains = contains;
    }
    
    public getSize() {
        return this.size;
    }
    public setSize(size: string) {
        this.size = size;
    }

    public getAvailablility(): boolean {
        return this.isAvailable;
    }
    public setAvailablility(flagStr: string) {
        this.isAvailable = (!this.isEmptyString(flagStr) && flagStr.toLowerCase() === "true") ? true : false ;
    }

    public getAllFeaturesFromKeyFeatures() {
        const allFeatures: string[] = this.keyFeatures.split('::');

        console.log(`AllFeatures : ${allFeatures}`);
        return allFeatures;
    }

    public getAllSpecifications() {
        const allScpec: string[] = this.specification.split('::');

        console.log(`AllFeatures : ${allScpec}`);
        return allScpec;
    }

    public getAllPackageContains() {
        const allContains: string[] = this.packageContains.split('::');

        console.log(`Contains : ${allContains}`);
        return allContains;
    }

    public getFlipkarFSN() : string {
        return this.flipkartFSN;
    }
    public setFlipkartFSN(sellerPID: string) {
        this.flipkartFSN = sellerPID;
    }

    public getAmazonASIN() : string {
        return this.amazonASIN;
    }
    public setAmazonASIN(sellerPID: string) {
        this.amazonASIN = sellerPID;
    }

    private isEmptyString(data: string) {
        if (data && data.trim() !=="") {
            return false;
        }
        return true;
    }

    private formImageLink(imageName:string)  {
        if (this.isEmptyString(imageName)) {
            return imageName;
        }
        const imgPath = "./assets/images/product/category/" + this.getCategory() + "/" + this.getSku() + "/" + imageName;
       // console.log("image path ", imgPath);
        return imgPath;
    }

}